import React, { useState } from 'react';
import Select from 'react-select';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { toastSuccess, toastError } from 'utils/toasts';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import { MdAdd } from 'react-icons/md';
import InputField from 'components/fields/InputField';
import { post } from 'api';

const statusOptions = [
  { value: 'Ready', label: 'Ready' },
  { value: 'Not Ready', label: 'Not Ready' },
  { value: 'Delete', label: 'Delete' },
];

const styles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: '1px solid #E5E5E5',
    borderRadius: '0.75rem',
    height: '48px',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#4B5563',
    fontFamily: 'DM Sans',
    fontWeight: '500',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid #E5E5E5',
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#f5f5f5'
        : isFocused
        ? '#f5f5f5'
        : null,
      color: isDisabled ? '#ccc' : '#4B5563',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'DM Sans',
      fontWeight: '500',
      '&:active': {
        backgroundColor: '#f5f5f5',
      },
    };
  },
  menu: (styles) => ({
    ...styles,
    marginTop: '4px',
    borderRadius: '8px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
  }),
  menuList: (styles) => ({
    ...styles,
    padding: '0',
    borderRadius: '8px',
    cursor: 'pointer',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#4B5563',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'DM Sans',
    fontWeight: '600',
    cursor: 'pointer',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#A0AEC0',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'DM Sans',
    fontWeight: '500',
  }),
};

const AddBacklinkModal = ({ getBacklinks, countries, getCountries }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newBacklinkData, setNewBacklinkData] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const handleDataChange = (e) => {
    setNewBacklinkData({ ...newBacklinkData, [e.target.id]: e.target.value });
  };

  const handleSelectChange = (selection, id, field) => {
    setNewBacklinkData({
      ...newBacklinkData,
      [field]: selection.value,
    });
  };

  const handleUpdateBacklink = async () => {
    setSubmitting(true);

    const {
      domain,
      price,
      firstCountryId,
      secondCountryId,
      thirdCountryId,
      time,
      deliveryTime,
      status,
      condition,
      wordCount,
      rating,
      rejectedTopics,
      doFollowLinks,
      domainRating,
      referringDomains,
      organicTraffic,
      organicKeywords,
      linkedDomains,
    } = newBacklinkData;

    const body = {
      domain,
      price,
      firstCountryId,
      secondCountryId,
      thirdCountryId,
      time,
      deliveryTime,
      status,
      condition,
      wordCount,
      rating,
      rejectedTopics,
      doFollowLinks,
    };

    try {
      const res = await post(`/api/marketplace/create`, body);

      if (res.status === 200) {
        const response = await res.json();
        const ahrefsBody = {
          marketplaceId: response.id,
          domainRating,
          referringDomains,
          organicTraffic,
          organicKeywords,
          linkedDomains,
        };

        await post(`/api/ahrefs/create`, ahrefsBody);

        toastSuccess('Marketplace updated successfully');
        getBacklinks();
        getCountries();
        onClose();
      } else {
        toastError('Something went wrong');
      }
    } catch (error) {
      toastError('Something went wrong');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <button
        onClick={onOpen}
        className="flex h-full w-full items-center justify-center rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        role="menuitem"
      >
        <MdAdd /> Add Backlink
      </button>
      <Modal className="!z-[100]" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[10vh] !m-auto max-w-[90%] md:!max-w-[30%]">
          <ModalBody className="max-h-[90vh] overflow-auto">
            <Card extra={'w-full p-4 rounded-md'}>
              <div className="relative flex items-center justify-center border-b-2 border-gray-200 dark:border-gray-700">
                Add Backlink
              </div>
              <div className="mt-4 flex max-h-[70vh] flex-col items-center gap-4 overflow-auto">
                <InputField
                  extra="w-full"
                  label="Article Domain"
                  id="domain"
                  type="text"
                  placeholder="Article domain eg. domain.com"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.domain || ''}
                />
                <InputField
                  extra="w-full"
                  label="Price"
                  id="price"
                  type="text"
                  placeholder="Price"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.price || ''}
                />
                <InputField
                  extra="w-full"
                  label="Domain Rating"
                  id="domainRating"
                  type="text"
                  placeholder="Domain rating"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.domainRating || ''}
                />
                <InputField
                  extra="w-full"
                  label="Referring Domains"
                  id="referringDomains"
                  type="text"
                  placeholder="Referring domains"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.referringDomains || ''}
                />
                <InputField
                  extra="w-full"
                  label="Organic Traffic"
                  id="organicTraffic"
                  type="text"
                  placeholder="Organic traffic"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.organicTraffic || ''}
                />
                <InputField
                  extra="w-full"
                  label="Organic Keywords"
                  id="organicKeywords"
                  type="text"
                  placeholder="Organic keywords"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.organicKeywords || ''}
                />
                <InputField
                  extra="w-full"
                  label="Linked Domains"
                  id="linkedDomains"
                  type="text"
                  placeholder="Linked domains"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.linkedDomains || ''}
                />
                <div className="w-full text-sm font-bold text-navy-700 dark:text-white">
                  <p className="mb-2 ml-3">First Country</p>
                  <Select
                    options={countries}
                    className="w-full"
                    value={
                      newBacklinkData['firstCountryId'] !== undefined
                        ? countries.find(
                            (option) =>
                              option.value ===
                              newBacklinkData['firstCountryId'],
                          )
                        : countries.find(
                            (option) =>
                              option.value === newBacklinkData.countryId,
                          )
                    }
                    styles={styles}
                    onChange={(selection) =>
                      handleSelectChange(
                        selection,
                        newBacklinkData.id,
                        'firstCountryId',
                      )
                    }
                  />
                </div>
                <div className="w-full text-sm font-bold text-navy-700 dark:text-white">
                  <p className="mb-2 ml-3">Second Country</p>
                  <Select
                    options={countries}
                    className="w-full"
                    value={
                      newBacklinkData['secondCountryId'] !== undefined
                        ? countries.find(
                            (option) =>
                              option.value ===
                              newBacklinkData['secondCountryId'],
                          )
                        : countries.find(
                            (option) =>
                              option.value === newBacklinkData.countryId,
                          )
                    }
                    styles={styles}
                    onChange={(selection) =>
                      handleSelectChange(
                        selection,
                        newBacklinkData.id,
                        'secondCountryId',
                      )
                    }
                  />
                </div>
                <div className="w-full text-sm font-bold text-navy-700 dark:text-white">
                  <p className="mb-2 ml-3">Third Country</p>
                  <Select
                    options={countries}
                    className="w-full"
                    value={
                      newBacklinkData['thirdCountryId'] !== undefined
                        ? countries.find(
                            (option) =>
                              option.value ===
                              newBacklinkData['thirdCountryId'],
                          )
                        : countries.find(
                            (option) =>
                              option.value === newBacklinkData.countryId,
                          )
                    }
                    styles={styles}
                    onChange={(selection) =>
                      handleSelectChange(
                        selection,
                        newBacklinkData.id,
                        'thirdCountry',
                      )
                    }
                  />
                </div>
                <div className="w-full text-sm font-bold text-navy-700 dark:text-white">
                  <p className="mb-2 ml-3">Status</p>
                  <Select
                    options={statusOptions}
                    className="w-full cursor-pointer"
                    value={
                      newBacklinkData.status
                        ? statusOptions.find(
                            (option) => option.value === newBacklinkData.status,
                          )
                        : statusOptions.find(
                            (option) => option.value === newBacklinkData.status,
                          )
                    }
                    styles={styles}
                    onChange={(selection) =>
                      handleSelectChange(
                        selection,
                        newBacklinkData.id,
                        'status',
                      )
                    }
                  />
                </div>
                <InputField
                  extra="w-full"
                  label="Condition"
                  id="condition"
                  type="text"
                  placeholder="Condition"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.condition || ''}
                />
                <InputField
                  extra="w-full"
                  label="Article Time"
                  id="time"
                  type="text"
                  placeholder="Article time"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.time || ''}
                />
                <InputField
                  extra="w-full"
                  label="Word Count"
                  id="wordCount"
                  type="text"
                  placeholder="Word count"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.wordCount || ''}
                />
                <InputField
                  extra="w-full"
                  label="Rejected Topics"
                  id="rejectedTopics"
                  type="text"
                  placeholder="Rejected topics"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.rejectedTopics || ''}
                />
                <InputField
                  extra="w-full"
                  label="Rating"
                  id="rating"
                  type="text"
                  placeholder="Rating"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.rating || ''}
                />
                <InputField
                  extra="w-full"
                  label="Do Follow Links"
                  id="doFollowLinks"
                  type="text"
                  placeholder="Do follow links"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.doFollowLinks || ''}
                />
                <InputField
                  extra="w-full"
                  label="Delivery Time"
                  id="deliveryTime"
                  type="text"
                  placeholder="Delivery time"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleDataChange(e)}
                  value={newBacklinkData.deliveryTime || ''}
                />
              </div>
              <div className="mt-5 flex justify-between">
                <button
                  onClick={() => onClose()}
                  className="bg-transparent ml-0 rounded-md border border-gray-500 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleUpdateBacklink()}
                  className="rounded-md bg-blue-500 px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  disabled={submitting}
                >
                  {submitting ? 'Adding...' : 'Add'}
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AddBacklinkModal;
