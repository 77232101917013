import React, { useMemo, useState, useEffect } from 'react';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import CryptoJS from 'crypto-js';
import Card from 'components/card';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { MdChevronLeft, MdChevronRight, MdDeleteForever } from 'react-icons/md';
import { get, put } from 'api';
import { FiSearch } from 'react-icons/fi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';
import InputField from './InputField';
import TooltipHorizon from 'components/tooltip';
import EditBacklinkModal from './EditBacklinkModal';
import { remove } from 'api';
import AddBacklinkModal from './AddBacklinksModal';

const statusOptions = [
  { value: 'Ready', label: 'Ready' },
  { value: 'Not Ready', label: 'Not Ready' },
  { value: 'Delete', label: 'Delete' },
];

const styles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: '1px solid #E5E5E5',
    borderRadius: '0.75rem',
    height: '48px',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#4B5563',
    fontFamily: 'DM Sans',
    fontWeight: '500',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #E5E5E5',
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#f5f5f5'
        : isFocused
        ? '#f5f5f5'
        : null,
      color: isDisabled ? '#ccc' : '#4B5563',
      cursor: isDisabled ? 'not-allowed' : 'default',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'DM Sans',
      fontWeight: '500',
      '&:active': {
        backgroundColor: '#f5f5f5',
      },
    };
  },
  menu: (styles) => ({
    ...styles,
    marginTop: '4px',
    borderRadius: '8px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  }),
  menuList: (styles) => ({
    ...styles,
    padding: '0',
    borderRadius: '8px',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#4B5563',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'DM Sans',
    fontWeight: '600',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#A0AEC0',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'DM Sans',
    fontWeight: '500',
  }),
};

const MarketplaceTable = (props) => {
  const { columnsData } = props;
  const [marketplaceData, setMarketplaceData] = useState([]);
  const [submitting, setSubmitting] = useState({});
  const [updates, setUpdates] = useState({});
  const [countries, setCountries] = useState([]);

  const getMarketplaceData = async () => {
    await get('/api/marketplace/all').then((res) =>
      res.json().then((response) => {
        const encryptedData = response.data;
        const bytes = CryptoJS.AES.decrypt(
          encryptedData,
          process.env.REACT_APP_CRYPTO_SECRET,
        );
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setMarketplaceData(data);
      }),
    );
  };

  const getCountriesData = async () => {
    await get('/api/admin/countries').then((res) =>
      res.json().then((data) => {
        const countriesOptions = [
          { value: 0, label: 'No country' },
          ...data.map((country) => ({
            value: country.id,
            label: country.country,
          })),
        ];
        setCountries(countriesOptions);
      }),
    );
  };

  useEffect(() => {
    getMarketplaceData();
    getCountriesData();
  }, []);

  const handleUpdateMarketplace = async (itemId) => {
    setSubmitting({ ...submitting, [itemId]: true });
    const body = {
      price: updates[itemId]?.price,
      firstCountryId: updates[itemId]?.firstCountryId,
      secondCountryId: updates[itemId]?.secondCountryId,
      thirdCountryId: updates[itemId]?.thirdCountryId,
      time: updates[itemId]?.time,
      deliveryTime: updates[itemId]?.deliveryTime,
      status: updates[itemId]?.status,
    };
    await put(`/api/marketplace/update/${itemId}`, body).then((res) => {
      if (res.status === 200) {
        toastSuccess('Marketplace updated successfully');
        getMarketplaceData();
        setSubmitting({ ...submitting, [itemId]: false });
      } else {
        toastError('Something went wrong');
      }
    });
  };

  const handleInputChange = (e, id, field) => {
    setUpdates({
      ...updates,
      [id]: {
        ...(updates[id] || {}),
        [field]: e.target.value,
      },
    });
  };

  const handleSelectChange = (selection, id, field) => {
    setUpdates({
      ...updates,
      [id]: {
        ...(updates[id] || {}),
        [field]: selection.value,
      },
    });
  };

  const handleRemoveBacklink = async (id) => {
    if (window.confirm('Are you sure you want to delete this backlink?')) {
      await remove(`/api/marketplace/delete/${id}`).then((res) => {
        if (res.status === 200) {
          toastSuccess('Backlink deleted successfully');
          getMarketplaceData();
          getCountriesData();
        } else {
          toastError('Something went wrong');
        }
      });
    }
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => marketplaceData, [marketplaceData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex w-full items-center justify-between">
        {/* Search */}
        <div
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="flex items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mt-2 md:w-3/4 md:w-[400px]"
        >
          <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
            <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
            <input
              type="text"
              placeholder="Search...."
              className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
            />
          </div>
        </div>
        <div className="mt-4 flex items-center justify-between">
          <AddBacklinkModal
            getBacklinks={getMarketplaceData}
            countries={countries}
            getCountries={getCountriesData}
          />
        </div>
      </div>

      <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] pr-16 dark:!border-navy-700"
                    key={index}
                  >
                    <div className="mr-[8px] text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      <div className="flex items-center gap-2">
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BsChevronUp className="mr-1 inline h-4 w-4" />
                          ) : (
                            <BsChevronDown className="mr-1 inline h-4 w-4" />
                          )
                        ) : (
                          <IoMdFunnel className="mr-1 inline h-4 w-4" />
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="h-[120px] items-center border-b border-gray-200 dark:!border-white/10"
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'ID') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            #{cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'Domain') {
                      let domain = cell.value;
                      if (!domain.startsWith('https://')) {
                        domain = 'https://' + domain;
                      }

                      data = (
                        <a
                          href={domain}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TooltipHorizon
                            trigger={
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value && cell.value.slice(0, 20)}
                                {cell.value && cell.value.length > 20 && (
                                  <span>...</span>
                                )}
                              </p>
                            }
                            content={
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {cell.value}
                              </p>
                            }
                            placement="top"
                          />
                        </a>
                      );
                    } else if (cell.column.Header === '1st Country') {
                      const itemId = cell.row.original.id;
                      const countryId = cell.value[0] && cell.value[0].id;
                      data = (
                        <div className="flex w-[170px] flex-col text-sm font-medium text-navy-700 dark:text-white">
                          <Select
                            options={countries}
                            className="w-full"
                            value={
                              updates[itemId] &&
                              updates[itemId]['firstCountryId'] !== undefined
                                ? countries.find(
                                    (option) =>
                                      option.value ===
                                      updates[itemId]['firstCountryId'],
                                  )
                                : countries.find(
                                    (option) => option.value === countryId,
                                  )
                            }
                            styles={styles}
                            onChange={(selection) =>
                              handleSelectChange(
                                selection,
                                itemId,
                                'firstCountryId',
                              )
                            }
                          />
                        </div>
                      );
                    } else if (cell.column.Header === '2nd Country') {
                      const itemId = cell.row.original.id;
                      const countryId = cell.value[0] && cell.value[0].id;
                      data = (
                        <div className="flex w-[170px] flex-col text-sm font-medium text-navy-700 dark:text-white">
                          <Select
                            options={countries}
                            className="w-full"
                            value={
                              updates[itemId] &&
                              updates[itemId]['secondCountryId'] !== undefined
                                ? countries.find(
                                    (option) =>
                                      option.value ===
                                      updates[itemId]['secondCountryId'],
                                  )
                                : countries.find(
                                    (option) => option.value === countryId,
                                  )
                            }
                            styles={styles}
                            onChange={(selection) =>
                              handleSelectChange(
                                selection,
                                itemId,
                                'secondCountryId',
                              )
                            }
                          />
                        </div>
                      );
                    } else if (cell.column.Header === '3rd Country') {
                      const itemId = cell.row.original.id;
                      const countryId = cell.value[0] && cell.value[0].id;
                      data = (
                        <div className="flex w-[170px] flex-col text-sm font-medium text-navy-700 dark:text-white">
                          <Select
                            options={countries}
                            className="w-full"
                            value={
                              updates[itemId] &&
                              updates[itemId]['thirdCountryId'] !== undefined
                                ? countries.find(
                                    (option) =>
                                      option.value ===
                                      updates[itemId]['thirdCountryId'],
                                  )
                                : countries.find(
                                    (option) => option.value === countryId,
                                  )
                            }
                            styles={styles}
                            onChange={(selection) =>
                              handleSelectChange(
                                selection,
                                itemId,
                                'thirdCountry',
                              )
                            }
                          />
                        </div>
                      );
                    } else if (cell.column.Header === 'Time') {
                      const itemId = cell.row.original.id;
                      data = (
                        <div className="flex w-[170px] flex-col text-sm font-medium text-navy-700 dark:text-white">
                          <InputField
                            type="text"
                            name="time"
                            value={updates[itemId]?.time || cell.value}
                            placeholder="Enter time"
                            className="w-full"
                            onChange={(e) =>
                              handleInputChange(e, itemId, 'time')
                            }
                          />
                        </div>
                      );
                    } else if (cell.column.Header === 'Delivery Time') {
                      const itemId = cell.row.original.id;
                      data = (
                        <div className="flex w-[60px] flex-col text-sm font-medium text-navy-700 dark:text-white">
                          <InputField
                            type="text"
                            name="deliveryTime"
                            value={updates[itemId]?.deliveryTime || cell.value}
                            placeholder="Enter delivery time"
                            className="w-1/2"
                            onChange={(e) =>
                              handleInputChange(e, itemId, 'deliveryTime')
                            }
                          />
                        </div>
                      );
                    } else if (cell.column.Header === 'Price') {
                      const itemId = cell.row.original.id;
                      data = (
                        <div className="w-[150px] text-sm font-bold text-navy-700 dark:text-white">
                          <InputField
                            type="text"
                            name="price"
                            value={updates[itemId]?.price || cell.value}
                            placeholder="Enter price"
                            className="w-full"
                            onChange={(e) =>
                              handleInputChange(e, itemId, 'price')
                            }
                          />
                        </div>
                      );
                    } else if (cell.column.Header === 'STATUS') {
                      const itemId = cell.row.original.id;
                      data = (
                        <div className="w-[130px] text-sm font-bold text-navy-700 dark:text-white">
                          <Select
                            options={statusOptions}
                            className="w-full"
                            value={
                              updates[itemId] && updates[itemId]['status']
                                ? statusOptions.find(
                                    (option) =>
                                      option.value ===
                                      updates[itemId]['status'],
                                  )
                                : statusOptions.find(
                                    (option) => option.value === cell.value,
                                  )
                            }
                            styles={styles}
                            onChange={(selection) =>
                              handleSelectChange(selection, itemId, 'status')
                            }
                          />
                        </div>
                      );
                    } else if (cell.column.Header === 'Actions') {
                      const itemId = cell.row.original.id;
                      data = (
                        <div className="flex h-full items-center gap-2">
                          <EditBacklinkModal
                            itemId={itemId}
                            data={cell.row.original}
                            getBacklinks={getMarketplaceData}
                            countries={countries}
                            getCountries={getCountriesData}
                          />
                          <button
                            onClick={() => handleRemoveBacklink(itemId)}
                            className="text-2xl font-bold text-navy-700 hover:text-red-500 dark:text-white dark:hover:text-red-500"
                          >
                            <MdDeleteForever />
                          </button>
                          <button
                            className="linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                            onClick={() => handleUpdateMarketplace(itemId)}
                          >
                            {submitting[itemId] ? 'Updating...' : 'Update'}
                          </button>
                        </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pb-[16px] pt-[14px] sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-10 flex h-[80px] w-full items-center justify-between px-6">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of{' '}
            {Math.ceil(marketplaceData.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default MarketplaceTable;
