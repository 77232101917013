import React, { useMemo, useState, useEffect, useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Select from 'react-select';
import Card from 'components/card';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  MdCancel,
  MdCheckCircle,
  MdChevronLeft,
  MdChevronRight,
  MdDeleteForever,
} from 'react-icons/md';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { FiSearch } from 'react-icons/fi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';
import { ProjectContext } from 'contexts/ProjectContext';
import TooltipHorizon from 'components/tooltip';
import EditBacklinkModal from './EditBacklinkModal';
import AddNewBacklinkModal from './AddNewBacklinkModal';
import { removeBL, getBL } from 'api';
import { get } from 'api';

const BacklinksTable = (props) => {
  const { columnsData } = props;
  const { currentProject } = useContext(ProjectContext);
  const [backlinks, setBacklinks] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [excelSubmitting, setExcelSubmitting] = useState(false);

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const handleExport = async () => {
    setExcelSubmitting(true);
    const fileName = currentProject.projectName + ' - Backlink Status';
    let excelData = backlinks;

    if (excelData.length === 0) {
      toastError('No data available to export');
      setExcelSubmitting(false);
      return;
    }

    excelData = excelData.map((report) => ({
      ID: report.id,
      'Article Domain': report.articleDomain,
      'Article URL': report.articleURL,
      'Target Anchor': report.targetAnchor,
      'Anchor Found': report.anchorFound,
      'Referring Domains': report.referringDomains,
      'Rel Attribute': report.relAttribute,
      'Rel Attribute Found': report.relAttributeFound,
      'Link Type': report.linkType,
      'Status Code': report.statusCode,
      'Backlink Status': report.backlinkStatus,
      'Service Provider': report.serviceProvider,
      'Updated At': moment(report.updatedAt).format('YYYY-MM-DD'),
    }));

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'orders');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const data = new Blob([s2ab(excelBuffer)], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    setExcelSubmitting(false);
  };

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const getAllProjects = async () => {
    await get('/api/project').then((res) =>
      res.json().then((data) => {
        const newData = data.map((item) => {
          return { value: item.id, label: item.projectName };
        });
        setProjects(newData);
        setLoaded(true);
      }),
    );
  };

  const getBacklinks = async () => {
    await getBL(`/api/linkjuice/backlinks/project/${currentProject.id}`).then(
      (res) =>
        res.json().then((data) => {
          setBacklinks(data.data);
        }),
    );
  };

  useEffect(() => {
    getAllProjects();
    if (currentProject.id) {
      getBacklinks();
    }
  }, [currentProject]);

  const handleRemoveBacklink = async (id) => {
    if (window.confirm('Are you sure you want to delete this backlink?')) {
      await removeBL(`/api/linkjuice/backlinks/delete/${id}`).then((res) => {
        if (res.status === 200) {
          toastSuccess('Backlink deleted successfully');
          getBacklinks();
        } else {
          toastError('Something went wrong');
        }
      });
    }
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => backlinks, [backlinks]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="mb-4 flex w-full items-center justify-between">
        <Select
          options={projects}
          value={selectedProject}
          onChange={(e) => {
            setSelectedProject(e);
            currentProject.id = e.value;
            getBacklinks();
          }}
          name="project"
          menuPlacement="top"
          menuPortalTarget={document.body}
          placeholder="Select Project.."
          className="w-1/4"
        />
        <button
          disabled={excelSubmitting}
          onClick={() => {
            backlinks.length > 0 && handleExport();
          }}
          className="linear flex w-auto items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
        >
          Export to CSV
        </button>
        <AddNewBacklinkModal getBacklinks={getBacklinks} />
      </div>

      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex w-3/4 items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mt-2 md:w-1/5"
      >
        <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] pr-16 dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      <div className="flex items-center gap-2">
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BsChevronUp className="mr-1 inline h-4 w-4" />
                          ) : (
                            <BsChevronDown className="mr-1 inline h-4 w-4" />
                          )
                        ) : (
                          <IoMdFunnel className="mr-1 inline h-4 w-4" />
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="h-[60px] items-center border-b border-gray-200 dark:!border-white/10"
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'ID') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            #{cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'DOMAIN NAME') {
                      const articleURL = cell.row.original.articleURL;
                      data = (
                        <TooltipHorizon
                          key={index}
                          extra="max-h-[200px] overflow-y-auto"
                          trigger={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              <a
                                href={articleURL}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {cell.value && cell.value.slice(0, 18)}
                                {cell.value && cell.value.length > 18 && (
                                  <span>...</span>
                                )}
                              </a>
                            </p>
                          }
                          content={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {articleURL}
                            </p>
                          }
                          placement="top"
                        />
                      );
                    } else if (cell.column.Header === 'ANCHOR') {
                      data = (
                        <TooltipHorizon
                          key={index}
                          extra="max-h-[200px] overflow-y-auto"
                          trigger={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value && cell.value.slice(0, 18)}
                              {cell.value && cell.value.length > 18 && (
                                <span>...</span>
                              )}
                            </p>
                          }
                          content={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          }
                          placement="top"
                        />
                      );
                    } else if (cell.column.Header === 'URL') {
                      data = (
                        <TooltipHorizon
                          key={index}
                          extra="max-h-[200px] overflow-y-auto"
                          trigger={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value && cell.value.slice(0, 18)}
                              {cell.value && cell.value.length > 18 && (
                                <span>...</span>
                              )}
                            </p>
                          }
                          content={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          }
                          placement="top"
                        />
                      );
                    } else if (cell.column.Header === 'ARTICLE URL') {
                      data = (
                        <TooltipHorizon
                          key={index}
                          extra="max-h-[200px] overflow-y-auto"
                          trigger={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              <a
                                href={cell.value}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {cell.value && cell.value.slice(0, 18)}
                                {cell.value && cell.value.length > 18 && (
                                  <span>...</span>
                                )}
                              </a>
                            </p>
                          }
                          content={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          }
                          placement="top"
                        />
                      );
                    } else if (cell.column.Header === 'ANCHOR FOUND') {
                      data = (
                        <TooltipHorizon
                          key={index}
                          extra="max-h-[200px] overflow-y-auto"
                          trigger={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value && cell.value.slice(0, 18)}
                              {cell.value && cell.value.length > 18 && (
                                <span>...</span>
                              )}
                            </p>
                          }
                          content={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          }
                          placement="top"
                        />
                      );
                    } else if (cell.column.Header === 'REL ATTRIBUTE') {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === 'REL ATTRIBUTE FOUND') {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === 'LINK TYPE') {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === 'SERVICE PROVIDER') {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === 'BACKLINK STATUS') {
                      const status = cell.value;
                      data = (
                        <div className="flex items-center gap-2">
                          <div className={`rounded-full text-xl`}>
                            {status === 'Active' ? (
                              <MdCheckCircle className="text-green-500" />
                            ) : status === 'Inactive' ? (
                              <MdCancel className="text-red-500" />
                            ) : (
                              ''
                            )}
                          </div>
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === 'STATUS CODE') {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === 'ACTIONS') {
                      const itemId = cell.row.original.id;
                      const articleDomain = cell.row.original.articleDomain;
                      const articleURL = cell.row.original.articleURL;
                      const targetURL = cell.row.original.targetURL;
                      const targetAnchor = cell.row.original.targetAnchor;
                      const relAttribute = cell.row.original.relAttribute;
                      const serviceProvider = cell.row.original.serviceProvider;
                      data = (
                        <div className="flex items-center gap-2">
                          <EditBacklinkModal
                            itemId={itemId}
                            articleDomain={articleDomain}
                            articleURL={articleURL}
                            targetURL={targetURL}
                            targetAnchor={targetAnchor}
                            relAttribute={relAttribute}
                            serviceProvider={serviceProvider}
                            getBacklinks={getBacklinks}
                          />
                          <button
                            onClick={() => handleRemoveBacklink(itemId)}
                            className="text-2xl font-bold text-navy-700 hover:text-red-500 dark:text-white dark:hover:text-red-500"
                          >
                            <MdDeleteForever />
                          </button>
                        </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pb-[16px] pt-[14px] sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-10 flex h-[80px] w-full items-center justify-between px-6">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of {Math.ceil(backlinks.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default BacklinksTable;
