export const backlinksHeaders = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'DOMAIN NAME',
    accessor: 'articleDomain',
  },
  {
    Header: 'URL',
    accessor: 'targetURL',
  },
  {
    Header: 'ANCHOR',
    accessor: 'targetAnchor',
  },
  {
    Header: 'ANCHOR FOUND',
    accessor: 'anchorFound',
  },
  {
    Header: 'REL ATTRIBUTE',
    accessor: 'relAttribute',
  },
  {
    Header: 'REL ATTRIBUTE FOUND',
    accessor: 'relAttributeFound',
  },
  {
    Header: 'LINK TYPE',
    accessor: 'linkType',
  },
  {
    Header: 'BACKLINK STATUS',
    accessor: 'backlinkStatus',
  },
  {
    Header: 'STATUS CODE',
    accessor: 'statusCode',
  },
  {
    Header: 'SERVICE PROVIDER',
    accessor: 'serviceProvider',
  },
  {
    Header: 'ACTIONS',
    accessor: 'delete',
  },
];
