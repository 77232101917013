import React, { useState, useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { toastSuccess, toastError } from 'utils/toasts';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import { ProjectContext } from 'contexts/ProjectContext';
import { getUserId, postBL } from 'api';

const AddNewBacklinkModal = ({ getBacklinks }) => {
  const { currentProject } = useContext(ProjectContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newTargetUrl, setNewTargetUrl] = useState('');
  const [newTargetAnchor, setNewTargetAnchor] = useState('');
  const [newArticleDomain, setNewArticleDomain] = useState('');
  const [newArticleUrl, setNewArticleUrl] = useState('');
  const [newServiceProvider, setNewServiceProvider] = useState('');
  const [newRelAttribute, setNewRelAttribute] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleNewArticleDomain = (e) => {
    setNewArticleDomain(e.target.value);
  };

  const handleNewArticleUrl = (e) => {
    setNewArticleUrl(e.target.value);
  };

  const handleNewTargetUrl = (e) => {
    setNewTargetUrl(e.target.value);
  };

  const handleNewTargetAnchor = (e) => {
    setNewTargetAnchor(e.target.value);
  };

  const handleNewServiceProvider = (e) => {
    setNewServiceProvider(e.target.value);
  };

  const handleNewRelAttribute = (e) => {
    setNewRelAttribute(e.target.value);
  };

  const handleNewBacklinkAdd = async () => {
    setSubmitting(true);
    if (
      !newTargetUrl ||
      !newTargetAnchor ||
      !newArticleDomain ||
      !newArticleUrl
    ) {
      toastError('Please fill all the fields');
      setSubmitting(false);
    } else {
      const userId = getUserId();
      const body = {
        projectId: currentProject.id,
        userId: userId,
        articleDomain: newArticleDomain,
        articleURL: newArticleUrl,
        targetURL: newTargetUrl,
        targetAnchor: newTargetAnchor,
        serviceProvider: newServiceProvider,
        relAttribute: newRelAttribute,
      };
      await postBL('/api/linkjuice/backlinks/create-backlink', body).then(
        (res) => {
          if (res.status === 200) {
            toastSuccess('Backlink added successfully');
            setSubmitting(false);
            setNewTargetUrl('');
            setNewTargetAnchor('');
            setNewArticleDomain('');
            setNewArticleUrl('');
            setNewServiceProvider('');
            setNewRelAttribute('');
            getBacklinks();
            onClose();
          } else {
            toastError('Backlink already exists');
          }
        },
      );
    }
  };

  return (
    <div>
      <button
        onClick={onOpen}
        className="linear flex w-full items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
        role="menuitem"
      >
        Add New Backlink
      </button>
      <Modal className="!z-[100]" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[10vh] !m-auto max-w-[90%] md:!max-w-[30%]">
          <ModalBody className="max-h-[90vh] overflow-auto">
            <Card extra={'w-full p-4 rounded-md'}>
              <div className="relative flex items-center justify-center border-b-2 border-gray-200 dark:border-gray-700">
                Add Backlink
              </div>
              <div className="mt-4 flex flex-col items-center gap-4">
                <InputField
                  extra="w-full"
                  label="Article Domain"
                  id="articleDomain"
                  type="text"
                  placeholder="Article domain eg. domain.com"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleNewArticleDomain(e)}
                  value={newArticleDomain || ''}
                />
                <InputField
                  extra="w-full"
                  label="Article URL"
                  id="articleUrl"
                  type="text"
                  placeholder="Article url eg. domain.com/article"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleNewArticleUrl(e)}
                  value={newArticleUrl || ''}
                />
                <InputField
                  extra="w-full"
                  label="Target URL"
                  id="domainName"
                  type="text"
                  placeholder="Target domain eg. https://domain.com"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleNewTargetUrl(e)}
                  value={newTargetUrl || ''}
                />
                <InputField
                  extra="w-full"
                  label="Target Anchor"
                  id="anchor"
                  type="text"
                  placeholder="Target anchor eg. anchor text"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleNewTargetAnchor(e)}
                  value={newTargetAnchor || ''}
                />
                <InputField
                  extra="w-full"
                  label="Rel Attribute"
                  id="relAttribute"
                  type="text"
                  placeholder="Rel attribute eg. nofollow"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleNewRelAttribute(e)}
                  value={newRelAttribute || ''}
                />
                <InputField
                  extra="w-full"
                  label="Service Provider"
                  id="provider"
                  type="text"
                  placeholder="Service provider"
                  state="normal"
                  disabled={false}
                  onChange={(e) => handleNewServiceProvider(e)}
                  value={newServiceProvider || ''}
                />
              </div>
              <div className="mt-5 flex justify-between">
                <button
                  onClick={() => onClose()}
                  className="bg-transparent ml-0 rounded-md border border-gray-500 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleNewBacklinkAdd()}
                  disabled={submitting}
                  className="rounded-md bg-blue-500 px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  {submitting ? 'Adding...' : 'Add'}
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AddNewBacklinkModal;
