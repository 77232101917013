export const projectsHeaders = [
  {
    Header: 'PROJECT ID',
    accessor: 'id',
  },
  {
    Header: 'PROJECT NAME',
    accessor: 'projectName',
  },
  {
    Header: 'DOMAIN NAME',
    accessor: 'domainName',
  },
  {
    Header: 'CATEGORIES',
    accessor: 'categories',
  },
  {
    Header: 'COUNTRIES',
    accessor: 'countries',
  },
  {
    Header: 'LANGUAGES',
    accessor: 'languages',
  },
  {
    Header: 'DESCRIPTION',
    accessor: 'description',
  },
  {
    Header: 'SUGGESTION ID',
    accessor: 'suggestionId',
  },
  {
    Header: 'MARKETPLACE DOMAIN',
    accessor: 'marketplaceDomain',
  },
  {
    Header: 'UPDATE',
    accessor: 'update',
  },
];

export const suggestionsHeaders = [
  {
    Header: 'PROJECT ID',
    accessor: 'projectId',
  },
  {
    Header: 'PROJECT NAME',
    accessor: 'projectName',
  },
  {
    Header: 'PROJECT DOMAIN',
    accessor: 'projectDomain',
  },
  {
    Header: 'SUGGESTION ID',
    accessor: 'marketplaceId',
  },
  {
    Header: 'SUGGESTION DOMAIN',
    accessor: 'suggestionDomain',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
  {
    Header: 'REASON',
    accessor: 'denyReason',
  },
  {
    Header: 'DELETE',
    accessor: 'delete',
  },
];

export const marketplaceHeaders = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Domain',
    accessor: 'domain',
  },
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
  },
];
