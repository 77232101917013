import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import ProvideContentForm from './ProvideContentForm';
import { toastError, toastSuccess } from 'utils/toasts';
import { put, post, upload } from 'api';

const ArticleModal = ({ itemId, projectId, fetchOrders }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [provideContentData, setProvideContentData] = useState({});

  const handleCancelButton = () => {
    setProvideContentData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[itemId];
      return updatedData;
    });
  };

  const handleApproveButton = () => {
    const { anchors, instructions, uploadFiles } =
      provideContentData[itemId] || {};
    const missingFields = [];
    if (!anchors) missingFields.push('anchors');
    if (!uploadFiles) missingFields.push('upload files');
    if (instructions && instructions.length > 2500) {
      toastError('Instructions should be less than 2500 characters');
      return;
    }
    if (missingFields.length > 0) {
      const missingFieldsString = missingFields.join(', ');
      if (missingFieldsString === 'anchors') {
        toastError(
          `Please fill in all the fields before clicking the 'Add' button. Ensure that you haven't missed any information, and then proceed to click 'Add' to complete the process.`,
        );
        return;
      } else if (missingFieldsString === 'upload files') {
        toastError(
          `Please upload the file before clicking the 'Submit' button. Ensure that you haven't missed any information, and then proceed further to complete the process.`,
        );
        return;
      } else {
        toastError(
          `Please fill in all the fields before clicking the 'Submit' button. Ensure that you haven't missed any information, and then proceed to click 'Submit' to complete the process.`,
        );
        return;
      }
    } else {
      handleProvideContent();
      onClose();
    }
  };

  const handleProvideContentData = (data) => {
    const id = data.itemId;
    setProvideContentData((prevData) => {
      const updatedData = { ...prevData };
      if (updatedData[id]) {
        updatedData[id] = {
          ...updatedData[id],
          ...data,
        };
      } else {
        updatedData[id] = {
          itemId: id,
          ...data,
        };
      }

      return updatedData;
    });
  };

  const handleProvideContent = async () => {
    const orderId = itemId;
    const anchorArray = Object.values(provideContentData[itemId].anchors);
    const anchors = anchorArray.map((anchor) => anchor.anchor.value);
    const anchorId = anchorArray.map((anchor) => anchor.anchor.id);
    const slugs = anchorArray.map((anchor) => anchor.url);
    const slugId = anchorArray.map((anchor) => anchor.urlSlugId);
    const instructions = provideContentData[itemId].instructions;
    const uploadFiles = provideContentData[itemId].uploadFiles;
    const formData = new FormData();
    formData.append('file', uploadFiles[0]);
    const urlAnchorPairs = slugs
      .map((slug, index) => {
        return {
          urlSlugId: slugId[index],
          urlSlug: slug,
          urlAnchor: anchors[index],
          anchorId: anchorId[index],
        };
      })
      .flat();
    const findNewUrls = urlAnchorPairs.filter((item) => item.urlSlugId === '');
    const newUrlAnchorToAdd = findNewUrls.map((item) => {
      const { urlSlug, urlAnchor } = item;
      const newUrlAnchor = {
        orderId,
        projectId,
        urlSlug,
        urlAnchor,
      };
      return newUrlAnchor;
    });
    await put(`/api/orders/instructions/${orderId}`, {
      instructions,
      articleStatus: 'DONE',
      orderStatus: 'IN PROGRESS',
    }).then((res) => {
      if (res.status === 200) {
        upload(`/api/orders/article-upload/${orderId}`, formData).then(
          (res) => {
            if (res.status === 200) {
              post('/api/orders/order-anchor', newUrlAnchorToAdd).then(
                (res) => {
                  if (res.status === 200) {
                    post('/api/urlanchors/order-slug', newUrlAnchorToAdd).then(
                      (res) =>
                        res.json().then((data) => {
                          const newSlugId = data.map((item) => item.id);
                          const newAnchorsAddition = findNewUrls.map(
                            (item, index) => {
                              const { urlAnchor } = item;
                              const newUrlAnchor = {
                                urlSlugId: newSlugId[index],
                                urlAnchor,
                              };
                              return newUrlAnchor;
                            },
                          );
                          post(
                            '/api/urlanchors/order-anchor',
                            newAnchorsAddition,
                          );
                          fetchOrders();
                        }),
                    );
                  }
                },
              );
            } else if (res.status === 413) {
              toastError('File size should be less than 10MB');
            }
          },
        );
        toastSuccess('Content provided successfully');
        fetchOrders();
      } else {
        toastError('Something went wrong');
      }
    });
  };

  return (
    <div>
      <button
        onClick={onOpen}
        className="flex h-7 items-center justify-center rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        role="menuitem"
      >
        Action
      </button>
      <Modal className="!z-[100]" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[5vh] !m-auto max-w-[90%] md:!max-w-[50%]">
          <ModalBody className="max-h-[90vh] overflow-auto">
            <Card extra={'w-full p-4 rounded-md'}>
              <div className="mt-5 flex flex-col">
                <div className="text-3xl dark:text-white">Provide content</div>
                <div className="mt-1 flex flex-col">
                  <ProvideContentForm
                    itemId={itemId}
                    provideContentData={provideContentData}
                    handleProvideContentData={handleProvideContentData}
                  />
                </div>
              </div>
              <div className="mt-5 flex justify-between">
                <button
                  onClick={() => {
                    handleCancelButton();
                    onClose();
                  }}
                  className="bg-transparent ml-0 rounded-md border border-gray-500 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleApproveButton}
                  className="rounded-md bg-blue-500 px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  Submit
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ArticleModal;
