import React, { useMemo, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { MdChevronLeft, MdChevronRight, MdDeleteForever } from 'react-icons/md';
import OrderActions from './OrderActions';
import { CartContext } from 'contexts/CartContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { post, get, put, getUserId, upload } from 'api';
import ArticleModal from './ArticleModal';
import TooltipHorizon from 'components/tooltip';

const CartTable = (props) => {
  const navigate = useNavigate();
  const { currentProject, getProjectInfo } = useContext(ProjectContext);
  const { columnsData } = props;
  const {
    cartItems,
    removeFromCartTable,
    removeAllFromCart,
    updateCartArticle,
  } = useContext(CartContext);
  const [articleStatus, setArticleStatus] = useState([]);
  const [hireContentWriterData, setHireContentWriterData] = useState([]);
  const [provideContentData, setProvideContentData] = useState([]);
  const [creditsBalance, setCreditsBalance] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const currencySymbol = localStorage.getItem('currency');

  const isMd = window.innerWidth > 768;

  const handleCheckSubmit = () => {
    fetchCredits();
    const isCreditsEnough =
      Number(creditsBalance) >= Number(totalPrice.toFixed(2));
    if (!isCreditsEnough) {
      toastError('You do not have enough credits, please top up');
      return;
    }
    if (Object.keys(currentProject).length === 0) {
      toastError('Please add project information first');
      setTimeout(() => {
        navigate('/admin/dashboards/projects/newProject');
      }, 2500);
      return;
    }
    if (cartItems.length === 0) {
      toastError('Your shopping cart is empty');
      return;
    }
    if (
      Object.values(articleStatus).find(
        (item) => item.articleStatus === 'Pending',
      )
    ) {
      toastError(
        'Please select article to be hired, provided or submitted later',
      );
      return;
    }
    if (cartItems.length > 0) {
      handleSubmit();
    }
  };

  const fetchCredits = async () => {
    const userId = getUserId();
    if (!userId) return;
    await get(`/api/user/${userId}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return;
        }
        const credits = data.credits;
        setCreditsBalance(credits);
      });
  };

  useEffect(() => {
    fetchCredits();
    const provide = cartItems.reduce((acc, item) => {
      const itemId = item.uniqueId;
      const provideContent = item.provideContent || {};
      const allUrls = currentProject.urls || [];
      return { ...acc, [itemId]: { ...provideContent, allUrls } };
    }, {});
    const hire = cartItems.reduce((acc, item) => {
      const itemId = item.uniqueId;
      const hireContent = item.hireContent || {};
      const allUrls = currentProject.urls || [];
      return { ...acc, [itemId]: { ...hireContent, allUrls } };
    }, {});
    const allArticleStatus = cartItems.reduce((acc, item) => {
      const itemId = item.uniqueId;
      const articleStatus = item.articleStatus || 'Pending';
      const articlePrice = item.hireContent?.articlePrice || 0;
      return { ...acc, [itemId]: { itemId, articleStatus, articlePrice } };
    }, {});
    setProvideContentData(provide);
    setHireContentWriterData(hire);
    setArticleStatus(allArticleStatus);
  }, [cartItems, currentProject]);

  const totalArticlePrice =
    Object.keys(hireContentWriterData).length > 0
      ? Object.values(hireContentWriterData).reduce((acc, curr) => {
          return curr.articlePrice ? acc + curr.articlePrice : acc;
        }, 0)
      : 0;

  const totalPrice =
    cartItems.reduce((acc, curr) => {
      return parseFloat(acc) + parseFloat(curr.price);
    }, 0) + totalArticlePrice;

  const handleArticleLater = (id, status) => {
    setArticleStatus((prevData) => {
      const updatedData = { ...prevData };
      if (updatedData[id]) {
        updatedData[id] = {
          ...updatedData[id],
          articleStatus: status,
        };
      } else {
        updatedData[id] = {
          itemId: id,
          articleStatus: status,
        };
      }
      return updatedData;
    });

    setHireContentWriterData((prevData) => {
      const updatedData = { ...prevData };
      if (updatedData[id]) {
        updatedData[id] = {
          ...updatedData[id],
          articlePrice: 0,
        };
      } else {
        updatedData[id] = {
          itemId: id,
          articlePrice: 0,
        };
      }
      return updatedData;
    });
  };

  const handleHireArticleData = (data) => {
    const id = data.itemId;
    setHireContentWriterData((prevData) => {
      const updatedData = { ...prevData };
      if (data.wordCount) {
        const wordCount = data.wordCount ? data.wordCount : 0;
        let price = 0;
        switch (wordCount) {
          case 300:
            price = 15;
            break;
          case 500:
            price = 25;
            break;
          case 750:
            price = 37.5;
            break;
          case 1000:
            price = 50;
            break;
          default:
            price = 0;
            break;
        }

        if (updatedData[id]) {
          updatedData[id] = {
            ...updatedData[id],
            ...data,
            articlePrice: price,
          };
          const hireContent = { hireContent: updatedData[id] };
          updateCartArticle(id, hireContent);
        } else {
          updatedData[id] = {
            itemId: id,
            ...data,
            articlePrice: price,
          };
          const hireContent = { hireContent: updatedData[id] };
          updateCartArticle(id, hireContent);
        }
      }

      if (updatedData[id]) {
        updatedData[id] = {
          ...updatedData[id],
          ...data,
        };
        const hireContent = { hireContent: updatedData[id] };
        updateCartArticle(id, hireContent);
      } else {
        updatedData[id] = {
          itemId: id,
          ...data,
        };
        const hireContent = { hireContent: updatedData[id] };
        updateCartArticle(id, hireContent);
      }

      return updatedData;
    });
  };

  const handleProvideContentData = (data) => {
    const id = data.itemId;
    setProvideContentData((prevData) => {
      const updatedData = { ...prevData };
      if (updatedData[id]) {
        updatedData[id] = {
          ...updatedData[id],
          ...data,
        };
        const ProvidedContent = { provideContent: updatedData[id] };
        updateCartArticle(id, ProvidedContent);
      } else {
        updatedData[id] = {
          itemId: id,
          ...data,
        };
        const ProvidedContent = { provideContent: updatedData[id] };
        updateCartArticle(id, ProvidedContent);
      }

      return updatedData;
    });
  };

  const handleCancelButton = (id, article) => {
    if (article === 'hire') {
      setHireContentWriterData((prevData) => {
        const updatedData = { ...prevData };
        if (updatedData[id]) {
          updatedData[id] = {};
        }
        return updatedData;
      });
      const hireContent = { articleStatus: null, hireContent: {} };
      updateCartArticle(id, hireContent);
    } else {
      setProvideContentData((prevData) => {
        const updatedData = { ...prevData };
        if (updatedData[id]) {
          updatedData[id] = {};
        }
        return updatedData;
      });
      const ProvidedContent = {
        articleStatus: null,
        provideContent: {},
      };
      updateCartArticle(id, ProvidedContent);
    }
  };

  const handleApproveButton = (id, article) => {
    if (article === 'later') {
      setProvideContentData((prevData) => {
        const updatedData = { ...prevData };
        if (updatedData[id]) {
          updatedData[id] = { provideLater: true };
        }
        return updatedData;
      });
      setHireContentWriterData((prevData) => {
        const updatedData = { ...prevData };
        if (updatedData[id]) {
          updatedData[id] = { provideLater: true, articlePrice: 0 };
        }
        return updatedData;
      });
      updateCartArticle(id, {
        articleStatus: 'LATER',
        hireContent: { provideLater: true, articlePrice: 0 },
        provideContent: { provideLater: true },
      });
    } else if (article === 'hire') {
      setProvideContentData((prevData) => {
        const updatedData = { ...prevData };
        if (updatedData[id]) {
          updatedData[id] = {};
        }
        return updatedData;
      });
      const provideContent = { articleStatus: 'HIRED', provideContent: {} };
      updateCartArticle(id, provideContent);
    } else {
      setHireContentWriterData((prevData) => {
        const updatedData = { ...prevData };
        if (updatedData[id]) {
          updatedData[id] = { articlePrice: 0 };
        }
        return updatedData;
      });
      const hireContent = {
        articleStatus: 'PROVIDED',
        hireContent: { articlePrice: 0 },
      };
      updateCartArticle(id, hireContent);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const ordersData = cartItems.map((item) => {
      const userId = getUserId();
      const {
        id,
        uniqueId,
        domain,
        domainRating,
        organicTraffic,
        referringDomains,
        linkedDomains,
        price,
        time,
      } = item;
      const today = new Date();
      const deadline = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);
      const articleData = hireContentWriterData[uniqueId] || {};
      const contentData = provideContentData[uniqueId] || {};
      const articleStat = articleStatus[uniqueId] || {};
      if (articleStat.articleStatus === 'LATER') {
        articleStatus[uniqueId].articleStatus = 'WAITING FOR ARTICLE';
      } else if (articleStat.articleStatus === 'HIRED') {
        articleStatus[uniqueId].articleStatus = 'WRITING';
      } else if (articleStat.articleStatus === 'PROVIDED') {
        articleStatus[uniqueId].articleStatus = 'DONE';
      }
      const orderData = {
        marketplaceId: id,
        projectId: currentProject.id,
        marketplace: uniqueId,
        userId,
        domain,
        domainRating,
        organicTraffic: Number(organicTraffic),
        referringDomains,
        linkedDomains,
        price: Number(price),
        time,
        orderStatus: 'IN PROGRESS',
        deadline: deadline.toISOString().split('T')[0],
        url: `https://${domain}`,
        ...articleStat,
        ...articleData,
        ...contentData,
        articleApproval: false,
      };
      return orderData;
    });
    const orderAnchors = cartItems.map((item) => {
      const { uniqueId } = item;
      const articleStat = articleStatus[uniqueId] || {};
      const articleData = hireContentWriterData[uniqueId] || {};
      const contentData = provideContentData[uniqueId] || {};
      const anchorId =
        articleStat.articleStatus === 'WRITING'
          ? Object.values(articleData.anchors).map((anchor) => anchor.anchor.id)
          : articleStat.articleStatus === 'DONE'
          ? Object.values(contentData.anchors).map((anchor) => anchor.anchor.id)
          : [];
      const anchor =
        articleStat.articleStatus === 'WRITING'
          ? Object.values(articleData.anchors).map(
              (anchor) => anchor.anchor.value,
            )
          : articleStat.articleStatus === 'DONE'
          ? Object.values(contentData.anchors).map(
              (anchor) => anchor.anchor.value,
            )
          : [];
      const slugs =
        articleStat.articleStatus === 'WRITING'
          ? Object.values(articleData.anchors).map((anchor) => anchor.url)
          : articleStat.articleStatus === 'DONE'
          ? Object.values(contentData.anchors).map((anchor) => anchor.url)
          : [];
      const slugId =
        articleStat.articleStatus === 'WRITING'
          ? Object.values(articleData.anchors).map((anchor) => anchor.urlSlugId)
          : articleStat.articleStatus === 'DONE'
          ? Object.values(contentData.anchors).map((anchor) => anchor.urlSlugId)
          : [];
      const orderAnchor = {
        uniqueId,
        slugId,
        slugs,
        anchorId,
        anchor,
      };
      return orderAnchor;
    });
    const urlAnchors = orderAnchors
      .map((item) => {
        const { uniqueId, slugId, slugs, anchorId, anchor } = item;
        const urlAnchorPairs = slugs.map((slug, index) => {
          return {
            uniqueId,
            urlSlugId: slugId[index],
            urlSlug: slug,
            urlAnchor: anchor[index],
            anchorId: anchorId[index],
          };
        });
        return urlAnchorPairs;
      })
      .flat();
    await post('/api/orders', ordersData).then((res) =>
      res.json().then((parsedData) => {
        if (res.status === 200) {
          const assignedUrlAnchors = urlAnchors.map((item) => {
            const { uniqueId, urlSlugId, urlSlug, urlAnchor, anchorId } = item;
            const order = parsedData.orders.find(
              (order) => order.marketplace === uniqueId,
            );
            const orderId = order.id;
            const urlAnchorData = {
              orderId,
              urlSlugId,
              urlSlug,
              anchorId,
              urlAnchor,
            };

            return urlAnchorData;
          });
          const findNewUrls = assignedUrlAnchors.filter(
            (item) => item.urlSlugId === '',
          );
          const newUrlAnchorToAdd = findNewUrls.map((item) => {
            const { urlSlug, urlAnchor } = item;
            const newUrlAnchor = {
              projectId: currentProject.id,
              urlSlug,
              urlAnchor,
            };
            return newUrlAnchor;
          });
          const assignedProvidedArticles = Object.values(
            provideContentData,
          ).map((item) => {
            const { itemId, uploadFiles } = item;
            if (!itemId) return;
            const order = parsedData.orders.find(
              (order) => order.marketplace === itemId,
            );
            const orderId = order.id;
            const providedArticle = {
              orderId,
              uploadFiles,
            };
            return providedArticle;
          });
          assignedProvidedArticles.forEach((item) => {
            if (!item) return;
            const { orderId, uploadFiles } = item;
            if (!uploadFiles) return;
            const formData = new FormData();
            formData.append('file', uploadFiles[0]);
            upload(`/api/orders/article-upload/${orderId}`, formData).then(
              (res) => {
                if (res.status === 413) {
                  toastError('File size should be less than 10MB');
                  return;
                }
              },
            );
          });
          post('/api/orders/order-anchor', assignedUrlAnchors).then((res) => {
            if (res.status === 200) {
              if (newUrlAnchorToAdd.length > 0) {
                post('/api/urlanchors/order-slug', newUrlAnchorToAdd).then(
                  (res) =>
                    res.json().then((data) => {
                      const newSlugId = data.map((item) => item.id);
                      const newUrlAnchorToAdd = findNewUrls.map(
                        (item, index) => {
                          const { urlAnchor } = item;
                          const newUrlAnchor = {
                            urlSlugId: newSlugId[index],
                            urlAnchor,
                          };
                          return newUrlAnchor;
                        },
                      );
                      post('/api/urlanchors/order-anchor', newUrlAnchorToAdd);
                    }),
                );
              }
              const userId = getUserId();
              put(`/api/orders/credits/${userId}`, {
                credits: totalPrice.toFixed(2),
              }).then((res) => {
                if (res.status === 200) {
                  setTimeout(() => {
                    fetchCredits();
                    removeAllFromCart();
                    navigate('/admin/orders/manager');
                  }, 2500);
                  toastSuccess('Order placed successfully');
                  setSubmitting(false);
                }
              });
            }
          });
        } else {
          toastError('Something went wrong');
        }
      }),
    );
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => cartItems, [cartItems]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {cartItems.length > 0 && (
        <div className="flex items-center justify-end">
          <button
            onClick={() => removeAllFromCart()}
            className="rounded-md bg-red-500 px-2 py-1 text-white transition duration-200 hover:bg-red-600"
          >
            Remove All
          </button>
        </div>
      )}
      <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] pr-16 dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      <div className="flex items-center gap-2">
                        {column.render('Header')}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {cartItems.length === 0 && (
              <tr>
                <td
                  colSpan="7"
                  className="pt-4 text-center text-sm font-bold text-gray-700 dark:text-white"
                >
                  Your shopping cart is empty
                </td>
              </tr>
            )}
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="h-[120px] items-center border-b border-gray-200 dark:!border-white/10"
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'ID') {
                      data = (
                        <div className="flex w-[60px] items-center gap-2">
                          <div className="text-sm font-medium text-navy-700 dark:text-white">
                            # {cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'DOMAIN') {
                      data = (
                        <TooltipHorizon
                          extra="max-h-[200px] overflow-y-auto"
                          trigger={
                            <p className="w-[150px] text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value && cell.value.slice(0, 18)}
                              {cell.value && cell.value.length > 18 && (
                                <span>...</span>
                              )}
                            </p>
                          }
                          content={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          }
                          placement="top"
                        />
                      );
                    } else if (cell.column.Header === 'PROJECT') {
                      data = (
                        <TooltipHorizon
                          extra="max-h-[200px] overflow-y-auto"
                          trigger={
                            <p className="w-[150px] text-sm font-bold text-navy-700 dark:text-white">
                              {currentProject.projectName &&
                                currentProject.projectName.slice(0, 25)}
                              {currentProject.projectName &&
                                currentProject.projectName.length > 25 && (
                                  <span>...</span>
                                )}
                            </p>
                          }
                          content={
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {currentProject.projectName}
                            </p>
                          }
                          placement="top"
                        />
                      );
                    } else if (cell.column.Header === 'ARTICLE') {
                      const itemId = cell.row.original.uniqueId;
                      const cellValue =
                        Object.values(articleStatus).find(
                          (item) => item.itemId === itemId,
                        )?.articleStatus || '';
                      data = (
                        <div className="w-[150px]">
                          <div
                            className={`flex h-auto w-[60%] items-center justify-center p-1 text-sm ${
                              cellValue === 'HIRED' || cellValue === 'PROVIDED'
                                ? 'bg-green-100 dark:bg-green-50'
                                : 'bg-red-100 dark:bg-red-50'
                            } rounded-[10px] text-base font-bold `}
                          >
                            <div
                              className={`${
                                cellValue === 'HIRED' ||
                                cellValue === 'PROVIDED'
                                  ? 'text-green-500 '
                                  : 'text-red-500'
                              } uppercase`}
                            >
                              {cellValue}
                            </div>
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'ORDER ACTIONS') {
                      const itemId = cell.row.original.uniqueId;
                      const articleWordCount = cell.row.original.wordCount;
                      const articleCondition = cell.row.original.condition;
                      data = (
                        <ArticleModal
                          itemId={itemId}
                          handleArticleLater={handleArticleLater}
                          handleHireArticleData={handleHireArticleData}
                          hireContentWriterData={hireContentWriterData}
                          provideContentData={provideContentData}
                          handleProvideContentData={handleProvideContentData}
                          handleCancelButton={handleCancelButton}
                          handleApproveButton={handleApproveButton}
                          setArticleStatus={setArticleStatus}
                          articleWordCount={articleWordCount}
                          articleCondition={articleCondition}
                        />
                      );
                    } else if (cell.column.Header === 'BACKLINK PRICE') {
                      data = (
                        <div className="w-[120px] text-sm font-bold text-navy-700 dark:text-white">
                          {currencySymbol}
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'ARTICLE PRICE') {
                      const itemId = cell.row.original.uniqueId;
                      data = (
                        <div className="w-[120px] text-sm font-bold text-navy-700 dark:text-white">
                          {currencySymbol}
                          {Object.values(hireContentWriterData).find(
                            (item) => item.itemId === itemId,
                          )?.articlePrice || 0}
                        </div>
                      );
                    } else if (cell.column.Header === 'DELETE') {
                      data = (
                        <button
                          onClick={() =>
                            removeFromCartTable(cell.row.original.uniqueId)
                          }
                          className="w-[100px] text-2xl font-bold text-navy-700 hover:text-red-500 dark:text-white dark:hover:text-red-500"
                        >
                          <MdDeleteForever />
                        </button>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pb-[16px] pt-[14px] sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {cartItems.length > 0 && (
            <tfoot>
              <tr>
                <td
                  colSpan={isMd ? 7 : 2}
                  className="pr-4 pt-4 text-right text-sm font-bold text-gray-700 dark:text-white"
                >
                  Total Price: {currencySymbol}
                  {totalPrice.toFixed(2)}
                </td>
                <td
                  colSpan="1"
                  className="pt-4 text-left text-sm font-bold text-gray-700 dark:text-white"
                >
                  <button
                    disabled={submitting}
                    className="rounded-md bg-brand-500 px-4 py-2 text-white transition duration-200 hover:bg-brand-600"
                    onClick={() => handleCheckSubmit()}
                  >
                    {submitting ? (
                      <div className="flex items-center justify-center">
                        <div className="mr-2 h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
                        <span>Buying..</span>
                      </div>
                    ) : (
                      <span>Buy</span>
                    )}
                  </button>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
      {/* pagination */}
      <div className="mt-10 flex h-[80px] w-full items-center justify-between md:px-6">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of {Math.ceil(cartItems.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default CartTable;
